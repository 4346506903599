<template>
  <div>
    <v-skeleton-loader
      v-if="isLoading"
      type="table"
    />

    <div v-else>
      <div class="pa-0 px-1 pb-3 w-full d-flex align-center justify-end">
        <v-dialog
          v-model="isFormOpen"
          width="500"
          persistent
        >
          <template #activator="{ on, attrs }">
            <v-btn
              fab
              small
              color="primary"
              :ripple="false"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                {{ icons.mdiPlus }}
              </v-icon>
            </v-btn>
          </template>

          <pay-system-form
            :key="`${paySystem.id}-${isFormOpen}`"
            :pay-system="paySystem"
            :pay-system-units="paySystemUnits"
            :users="users"
            :is-submitting="isSubmitting"
            @submit="submit($event)"
            @close="isFormOpen = false; paySystem = {}"
          />
        </v-dialog>
      </div>

      <v-data-table
        ref="dataTable"
        :key="`data-table-${paySystems.length}`"
        :headers="headers"
        :items="paySystems"
        :header-props="{ sortByText: 'ソート' }"
        hide-default-footer
        disable-pagination
        :expanded="paySystems"
        sort-by="attributes.bootTimeAt"
        sort-desc
        no-data-text="データがありません"
      >
        <template #[`item.attributes.bootTimeAt`]="{ item }">
          {{ new Date(item.attributes.bootTimeAt).toLocaleString('ja-JP').split(/:\d{1,2}$/)[0] }}
        </template>

        <template #[`item.actions`]="{item}">
          <span class="d-flex align-center justify-end">
            <v-btn
              icon
              :ripple="false"
              @click="
                paySystem = item;
                isFormOpen = true"
            >
              <v-icon small>
                {{ icons.mdiPencilOutline }}
              </v-icon>
            </v-btn>

            <yona-edit-dialog
              title="削除の確認"
              btn-color="error"
              save-text="同意の上で削除"
              @save="destroy(item.id)"
            >
              <template #append-outer-display-name>
                <v-btn
                  icon
                  :ripple="false"
                >
                  <v-icon small>
                    {{ icons.mdiTrashCanOutline }}
                  </v-icon>
                </v-btn>
              </template>

              <template #input>
                <span>
                  削除を実行してもよろしいですか
                </span>
              </template>
            </yona-edit-dialog>
          </span>
        </template>

        <template #expanded-item="{ item }">
          <td :colspan="headers.length">
            <v-row>
              <v-col
                cols="12"
                class="ps-4"
              >
                <v-treeview
                  :items="[{
                    id: JSON.stringify(item.attributes.paySystemUnitOrchestrations.data),
                    name: `構成ユニット(${item.attributes.paySystemUnitOrchestrations.data.length})`,
                    children: [{}],
                  }]"
                >
                  <template #label="{ item: node }">
                    <div
                      v-if="!node.name"
                      class="d-flex flex-wrap"
                      style="white-space: normal"
                    >
                      <div
                        v-for="(unit, unitIdx) in getSystemUnits(item)"
                        :key="`paySystem-${item.id}-users[${unitIdx}]`"
                        class="mr-4 mb-4"
                      >
                        <v-chip>
                          {{ unit.attributes.name }}
                        </v-chip>

                        <div class="ms-3 mt-1">
                          <small class="secondary--text">ユニット別基準日時</small>

                          <yona-edit-dialog
                            :display-name="lastCalcAtDisplayName(item, unit.id)"
                            class="py-2"
                            :is-valid="!hasError"
                            @open="buildLastCalcAtForm(item, unit.id)"
                            @save="submit(lastCalcAtForm)"
                            @close="lastCalcAtForm = {}"
                          >
                            <template #input>
                              <div
                                v-if="lastCalcAtForm.id"
                                class="pt-4"
                              >
                                <v-text-field
                                  ref="lastCalcAtInput"
                                  v-model="lastCalcAtForm.paySystemUnitOrchestrationsAttributes[0].lastCalcAt"
                                  type="datetime-local"
                                  :min="item.attributes.bootTimeAt"
                                  :rules="[
                                    () => {
                                      if (lastCalcAtForm.paySystemUnitOrchestrationsAttributes[0].lastCalcAt === null) return true

                                      if (dateTimeForJsFriendly(item.attributes.bootTimeAt.split(' ').join('T')) > dateTimeForJsFriendly(lastCalcAtForm.paySystemUnitOrchestrationsAttributes[0].lastCalcAt.split(' ').join('T'))) {
                                        return 'ユニット別基準日時はシステム基準日時より後の日時を指定してください';
                                      }

                                      return true
                                    },
                                  ]"
                                  class="pa-0 ma-0"
                                >
                                  <template #append-outer>
                                    <v-btn
                                      v-show="lastCalcAtForm.paySystemUnitOrchestrationsAttributes[0].lastCalcAt"
                                      :ripple="false"
                                      color="secondary"
                                      small
                                      @click="lastCalcAtForm.paySystemUnitOrchestrationsAttributes[0].lastCalcAt = null"
                                    >
                                      リセット
                                    </v-btn>
                                  </template>
                                </v-text-field>
                              </div>
                            </template>
                          </yona-edit-dialog>
                        </div>
                      </div>
                    </div>

                    <span v-else>{{ node.name }}</span>
                  </template>
                </v-treeview>
              </v-col>

              <v-col
                cols="12"
                class="ps-4"
              >
                <v-treeview
                  :items="[{
                    id: JSON.stringify(item.attributes.paySystemUsers.data),
                    name: `所属ユーザー(${item.attributes.paySystemUsers.data.length})`,
                    children: [{}],
                  }]"
                >
                  <template #label="{ item: node }">
                    <v-chip-group
                      v-if="!node.name"
                      column
                    >
                      <v-chip
                        v-for="(user, userIdx) in getSystemUsers(item)"
                        :key="`paySystem-${item.id}-users[${userIdx}]`"
                      >
                        <v-avatar left>
                          <v-img
                            v-if="user.attributes.profileImage"
                            :src="user.attributes.profileImage"
                          />
                          <v-icon v-else>
                            {{ icons.mdiAccountCircleOutline }}
                          </v-icon>
                        </v-avatar>

                        {{ user.attributes.name }}
                      </v-chip>
                    </v-chip-group>

                    <span v-else>{{ node.name }}</span>
                  </template>
                </v-treeview>
              </v-col>
            </v-row>
          </td>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { ref, computed, getCurrentInstance } from '@vue/composition-api'
import {
  mdiPlus,
  mdiClose,
  mdiAccountCircleOutline,
  mdiPencilOutline,
  mdiTrashCanOutline,
} from '@mdi/js'
import {
  findIndex,
  map,
  find,
  flatMap,
} from 'lodash'
import PaySystemApi from '@/api/admin/PaySystem'
import PaySystemUnitApi from '@/api/admin/PaySystemUnit'
import UserApi from '@/api/waiter/User'
import useCompInit from '@/views/composable/useCompInit'
import useDateFormat from '@/views/composable/useDateFormat'
import PaySystemForm from '@/views/components/pay_system/Form.vue'
import YonaEditDialog from '@/views/components/util/YonaEditDialog.vue'

export default {
  components: {
    PaySystemForm,
    YonaEditDialog,
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { isLoading, initWith } = useCompInit()
    const { dateTimeForJsFriendly } = useDateFormat()
    const isFormOpen = ref(false)
    const isSubmitting = ref(false)
    const paySystem = ref({})
    const paySystems = ref([])
    const paySystemUnits = ref([])
    const users = ref([])
    const lastCalcAtForm = ref({})
    const lastCalcAtInput = ref(null)

    const hasError = computed(() => {
      return lastCalcAtInput.value && lastCalcAtInput.value[0] && lastCalcAtInput.value[0].hasError
    })

    const getSystemUnits = item => {
      return map(item.attributes.paySystemUnitOrchestrations.data, o => {
        return find(paySystemUnits.value, unit => +unit.id === +o.attributes.paySystemUnitId)
      })
    }

    const getSystemUsers = item => {
      return flatMap(item.attributes.paySystemUsers.data, o => {
        return find(users.value, user => +user.id === +o.attributes.userId) || []
      })
    }

    const getSystemUnitOrchestration = (paySystemUnitOrchestrations, unitId) => {
      return find(paySystemUnitOrchestrations, o => +o.attributes.paySystemUnitId === +unitId)
    }

    const buildLastCalcAtForm = (systemItem, unitId) => {
      const targetOrchestration = getSystemUnitOrchestration(systemItem.attributes.paySystemUnitOrchestrations.data, unitId)

      lastCalcAtForm.value = {
        id: systemItem.id,
        paySystemUnitOrchestrationsAttributes: [{
          id: targetOrchestration?.id,
          lastCalcAt: dateTimeForJsFriendly(targetOrchestration?.attributes?.lastCalcAt || systemItem.attributes.bootTimeAt),
        }],
      }
    }

    const lastCalcAtDisplayName = (systemItem, unitId) => {
      const targetOrchestration = getSystemUnitOrchestration(systemItem.attributes.paySystemUnitOrchestrations.data, unitId)

      return targetOrchestration?.attributes?.lastCalcAt
        ? new Date(targetOrchestration?.attributes?.lastCalcAt).toLocaleString('ja-JP').split(/:\d{1,2}$/)[0]
        : 'なし'
    }

    const submit = async form => {
      isSubmitting.value = true
      const isUpdate = !!form.id
      const fnVerb = isUpdate ? 'update' : 'create'
      const {
        id,
        name,
        description,
        bootTimeAt,
        paySystemUsersAttributes,
        paySystemUnitOrchestrationsAttributes,
      } = form

      const res = await PaySystemApi[`${fnVerb}PaySystem`]({
        id,
        name,
        description,
        bootTimeAt,
        paySystemUsersAttributes,
        paySystemUnitOrchestrationsAttributes,
      })

      if (res?.data) {
        const paySystemIdx = findIndex(paySystems.value, o => +o.id === +res.data.paySystem.data.id)
        if (isUpdate) {
          paySystems.value.splice(paySystemIdx, 1, res.data.paySystem.data)
        } else {
          paySystems.value.unshift(res.data.paySystem.data)
        }

        vm.$toast.success(isUpdate ? '変動給ユニットを更新しました' : '変動給ユニットを追加しました')
      }

      isSubmitting.value = false
      isFormOpen.value = false
      paySystem.value = {}
    }

    const destroy = async id => {
      const res = await PaySystemApi.deletePaySystem(id)

      if (res) {
        const paySystemIdx = findIndex(paySystems.value, o => +o.id === +id)
        paySystems.value.splice(paySystemIdx, 1)
        vm.$toast.success('変動給システムを削除しました')
      }
    }

    const getPaySystems = async () => {
      const res = await PaySystemApi.getPaySystems()

      if (res?.data) paySystems.value = [...res.data.paySystems.data]
    }

    const getPaySystemUnits = async () => {
      const res = await PaySystemUnitApi.getPaySystemUnits()

      if (res?.data) paySystemUnits.value = [...res.data.paySystemUnits.data]
    }

    const getUsers = async () => {
      const res = await UserApi.getUsers(['cast', 'waiter', 'alliance'])

      if (res?.data) users.value = [...res.data.users.data]
    }

    initWith([
      getPaySystems(),
      getPaySystemUnits(),
      getUsers(),
    ])

    return {
      // data
      isLoading,
      isFormOpen,
      isSubmitting,
      paySystem,
      paySystems,
      paySystemUnits,
      lastCalcAtForm,
      lastCalcAtInput,
      users,
      headers: [
        {
          text: '名前',
          value: 'attributes.name',
          sortable: false,
        },
        {
          text: '説明',
          value: 'attributes.description',
          sortable: false,
        },
        {
          text: 'システム基準日時',
          value: 'attributes.bootTimeAt',
        },
        {
          text: '',
          value: 'actions',
          align: 'right',
          sortable: false,
        },
      ],

      // computed
      hasError,

      // methods
      getSystemUnits,
      getSystemUsers,
      getSystemUnitOrchestration,
      lastCalcAtDisplayName,
      buildLastCalcAtForm,
      submit,
      destroy,
      dateTimeForJsFriendly,

      icons: {
        mdiPlus,
        mdiClose,
        mdiAccountCircleOutline,
        mdiPencilOutline,
        mdiTrashCanOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/preset/preset/mixins.scss';

@include theme--child(v-data-table__expanded__content) using ($material) {
  td {
    background-color: map-deep-get($material, 'background');
  }
}

.v-data-table__expanded__content td {
  width: 100vw;
  vertical-align: top;

  &>div:first-child {
    padding: 12px 0 32px 0;

    &>.col {
      background-color: rgba(0,0,0,.05);
    }
  }
}
</style>
