<template>
  <v-card>
    <v-card-title>
      {{ form.id ? '変動給システム編集' : '新規変動給システム' }}
    </v-card-title>

    <v-card-text>
      <v-text-field
        v-model="form.name"
        label="名前"
        :error="!isValid.name"
      />

      <v-textarea
        v-model="form.description"
        label="説明"
        rows="2"
        :prepend-icon="icons.mdiCommentQuoteOutline"
      />

      <v-text-field
        v-model="form.bootTimeAt"
        label="システム基準日時"
        type="datetime-local"
        hide-details
        :error="!isValid.bootTimeAt"
        :min="dateTimeForJsFriendly(currentClub.createdAt)"
        class="pa-0 ma-0 my-8"
      />

      <div class="mt-4">
        <h4>
          構成ユニット
          ({{ form.paySystemUnitOrchestrationsAttributes.length }})
        </h4>

        <yona-has-many-v-select
          v-model="form.paySystemUnitOrchestrationsAttributes"
          parent-name="paySystem"
          :parent-id="form.id"
          :children="paySystemUnits"
          child-name="paySystemUnit"
          :error="!isValid.paySystemUnitOrchestrationsAttributes"
        />
      </div>

      <div class="mt-4">
        <h4>
          所属ユーザー
          ({{ form.paySystemUsersAttributes.length }})
        </h4>

        <yona-has-many-v-select
          v-model="form.paySystemUsersAttributes"
          parent-name="paySystem"
          :parent-id="form.id"
          :children="userItems"
          child-name="user"
          :error="!isValid.paySystemUsersAttributes"
        />
      </div>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        :ripple="false"
        :disabled="!(Object.values(isValid).every(isValid => isValid))"
        :loading="isSubmitting"
        @click="$emit('submit', form)"
      >
        {{ paySystem.id ? '更新' : '作成' }}
      </v-btn>

      <v-btn
        color="blue darken-1"
        :ripple="false"
        text
        @click="$emit('close')"
      >
        キャンセル
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  ref,
  computed,
  onMounted,
} from '@vue/composition-api'
import {
  map,
  chain,
  uniqWith,
  isEqual,
  groupBy,
  each,
  keys,
} from 'lodash'
import {
  mdiCommentQuoteOutline,
} from '@mdi/js'
import useValidation from '@/views/composable/useValidation'
import useCurrentData from '@/views/composable/useCurrentData'
import useDateFormat from '@/views/composable/useDateFormat'
import YonaHasManyVSelect from '@/views/components/util/YonaHasManyVSelect.vue'

export default {
  components: {
    YonaHasManyVSelect,
  },
  props: {
    paySystem: {
      type: Object,
      required: true,
      default: () => {},
    },
    paySystemUnits: {
      type: Array,
      required: true,
      default: () => [],
    },
    users: {
      type: Array,
      required: true,
      default: () => [],
    },
    isSubmitting: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { isValidDate } = useValidation()
    const { currentClub } = useCurrentData()
    const { dateTimeForJsFriendly } = useDateFormat()
    const form = ref({
      name: '',
      description: '',
      bootTimeAt: dateTimeForJsFriendly(new Date()),
      paySystemUsersAttributes: [],
      paySystemUnitOrchestrationsAttributes: [],
    })

    const userItems = computed(() => {
      const roleMsg = {
        cast: 'キャスト',
        waiter: 'ボーイ',
        alliance: 'アライアンス',
      }
      const lists = []
      const usersGrouped = groupBy(props.users, 'attributes.role')

      each(keys(roleMsg), role => {
        if (!usersGrouped[role]) return

        lists.push({ header: roleMsg[role] })
        lists.push(...usersGrouped[role])
      })

      return lists
    })

    const isEdit = computed(() => !!props.paySystem.id)

    const isValidPaySystemUsersAttributes = computed(() => {
      const { paySystemUsersAttributes } = form.value

      const isUniq = chain(paySystemUsersAttributes)
        .map(o => [+o.userId, +o.paySystemId])
        .uniqWith(isEqual)
        .value()
        .length === paySystemUsersAttributes.length

      return isUniq
    })

    const isValidPaySystemUnitOrchestrationsAttributes = computed(() => {
      const { paySystemUnitOrchestrationsAttributes } = form.value

      const isUniq = chain(paySystemUnitOrchestrationsAttributes)
        .map(o => [+o.paySystemId, +o.paySystemUnitId])
        .uniqWith(isEqual)
        .value()
        .length === paySystemUnitOrchestrationsAttributes.length

      return isUniq
    })

    const isValid = computed(() => {
      const { name, bootTimeAt } = form.value

      return {
        name,
        bootTimeAt: isValidDate(bootTimeAt.split(' ').join('T')) && new Date(currentClub.value.createdAt) <= new Date(bootTimeAt.split(' ').join('T')),
        paySystemUsersAttributes: isValidPaySystemUsersAttributes.value,
        paySystemUnitOrchestrationsAttributes: isValidPaySystemUnitOrchestrationsAttributes.value,
      }
    })

    const initForm = () => {
      if (isEdit.value) {
        const {
          name,
          description,
          bootTimeAt,
          paySystemUsers,
          paySystemUnitOrchestrations,
        } = props.paySystem.attributes

        const paySystemUsersAttributes = map(paySystemUsers.data, o => {
          const { userId, paySystemId } = o.attributes

          return {
            id: o.id,
            userId,
            paySystemId,
            _destroy: false,
          }
        })

        const paySystemUnitOrchestrationsAttributes = map(paySystemUnitOrchestrations.data, o => {
          const {
            lastCalcAt,
            position,
            paySystemId,
            paySystemUnitId,
          } = o.attributes

          return {
            id: o.id,
            lastCalcAt,
            position,
            paySystemId,
            paySystemUnitId,
            _destroy: false,
          }
        })

        form.value = {
          id: props.paySystem.id,
          name,
          description,
          bootTimeAt: dateTimeForJsFriendly(bootTimeAt),
          paySystemUsersAttributes,
          paySystemUnitOrchestrationsAttributes,
        }
      }
    }

    onMounted(() => initForm())

    return {
      // data
      form,

      // computed
      userItems,
      isEdit,
      isValid,

      // methods
      dateTimeForJsFriendly,

      icons: {
        mdiCommentQuoteOutline,
      },
    }
  },
}
</script>
