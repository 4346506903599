import { serialize } from 'object-to-formdata'
import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getPaySystems() {
    return axiosIns.get('/admin/pay_systems')
  },
  createPaySystem({
    name,
    description,
    bootTimeAt,
    paySystemUsersAttributes,
    paySystemUnitOrchestrationsAttributes,
  }) {
    const formData = serialize(snakecaseKeys({
      paySystem: {
        name,
        description,
        bootTimeAt,
        paySystemUsersAttributes,
        paySystemUnitOrchestrationsAttributes,
      },
    }))

    return axiosIns.post('/admin/pay_systems', formData)
  },
  updatePaySystem({
    id,
    name,
    description,
    bootTimeAt,
    paySystemUsersAttributes,
    paySystemUnitOrchestrationsAttributes,
  }) {
    const formData = serialize(snakecaseKeys({
      paySystem: {
        name,
        description,
        bootTimeAt,
        paySystemUsersAttributes,
        paySystemUnitOrchestrationsAttributes,
      },
    }))

    return axiosIns.put(`/admin/pay_systems/${id}`, formData)
  },
  deletePaySystem(id) {
    return axiosIns.delete(`/admin/pay_systems/${id}`)
  },
  initEarnings({ date, interval = 0, endDate = null }) {
    return axiosIns.get(
      'admin/pay_systems/init_earnings',
      {
        params: snakecaseKeys({
          date,
          interval,
          endDate,
        }),
      },
      { timeout: 8000 },
    )
  },
}
